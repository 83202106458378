import React from "react";

export const useDevice = () => {
    const [firstLoad, setFirstLoad] = React.useState(true);
    React.useEffect(() => {
        setFirstLoad(false);
    }, []);

    const ssr = firstLoad || typeof navigator === "undefined";

    const isAndroid = !ssr && /android/i.test(navigator.userAgent);
    const isIos =
        !ssr &&
        /iPad|iPhone|iPod/.test(navigator.userAgent) &&
        // @ts-ignore
        !window.MSStream;

    return {
        isAndroid,
        isIos,
        isDesktopDevice: !isAndroid && !isIos,
        isMobileDevice: isAndroid || isIos,
    };
};
