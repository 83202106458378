import SyncLoader from "react-spinners/SyncLoader";
import { core_palette } from "utilities/palette";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";

type Props = {
    emptyPage?: boolean;
    overrideColor?: string;
    small?: boolean;
};

const bounce = keyframes`
  0%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-4px);
  }
`;

export const BouncingDot = styled.div<{ color?: string }>`
    display: inline-block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: ${({ color }) => color || core_palette.primary.light};
    animation: ${bounce} 1s ease-in-out infinite;
    margin: 0 2px;
`;

const SmallLoader = ({ color }: { color?: string }) => {
    return (
        <div className="h-full transform translate-y-[4px]">
            <BouncingDot color={color} style={{ animationDelay: "-0.32s" }} />
            <BouncingDot color={color} style={{ animationDelay: "-0.16s" }} />
            <BouncingDot color={color} />
        </div>
    );
};

export default function Loading({ emptyPage, overrideColor, small }: Props) {
    if (small) {
        return <SmallLoader color={overrideColor} />;
    }
    if (emptyPage) {
        return (
            <div className="min-w-screen min-h-screen flex justify-center items-center">
                <SyncLoader
                    size={8}
                    color={overrideColor ?? core_palette.primary.light}
                />
            </div>
        );
    }
    return (
        <div className={`p-8 justify-center items-center flex`}>
            <SyncLoader
                size={8}
                color={overrideColor ?? core_palette.primary.light}
            />
        </div>
    );
}
