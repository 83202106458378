import clsx from "clsx";
import React from "react";
import * as Dialog from "@radix-ui/react-dialog";
import Button from "components/ui/Button";
import { ButtonMode } from "types";
import { Icon } from "components/ui/Icon";
import { useDevice } from "hooks/useDevice";
import { useUITheme } from "hooks/useUITheme";
import UIButton from "./UIButton";

type Props = {
    isOpen: boolean;
    setIsOpen?: (isOpen: boolean) => void;
    title?: string;
    onClickLeft?: () => void;
    onClickRight?: () => void;
    leftTitle?: string;
    rightTitle?: string;
    leftIcon?: Icon;
    rightIcon?: Icon;
    children?: React.ReactNode;
    description?: string;
    fixedWidth?: boolean;
};
const DetachedBox = (props: Props) => {
    const {
        isOpen,
        setIsOpen,
        children,
        leftIcon = "no_icon",
        leftTitle = "",
        title = "",
        description = "",
        rightIcon = "no_icon",
        rightTitle = "",
        onClickLeft = () => {},
        onClickRight = () => {},
        fixedWidth = false,
    } = props;

    const _theme = useUITheme();
    const { isMobileDevice } = useDevice();
    const contentStyles = clsx(
        "rounded-xl flex flex-col data-[state=open]:animate-dialogContentShow fixed top-[50%] left-[50%] max-h-[80vh] translate-x-[-50%] translate-y-[-50%] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none",
        !isMobileDevice && fixedWidth
            ? `w-[391px] max-w-md`
            : "w-[90vw] max-w-3xl",
    );

    return (
        <Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
            <Dialog.Portal>
                <Dialog.Overlay className="bg-blackA9 data-[state=open]:animate-fadeIn fixed inset-0" />
                <Dialog.Content
                    id="detached-box"
                    className={contentStyles}
                    style={{
                        backgroundColor: _theme.background_color,
                    }}
                >
                    <div className="flex flex-row justify-between items-center py-2 border-b">
                        <div className="flex-1">
                            <div>
                                <UIButton
                                    mode={ButtonMode.ghost}
                                    onClick={onClickLeft}
                                    title={leftTitle}
                                    icon={leftIcon}
                                />
                            </div>
                        </div>
                        <div className="text-lg text-center font-semibold flex-2">
                            {title}
                        </div>
                        <div className="flex-1 flex flex-row justify-end">
                            <div>
                                <UIButton
                                    mode={ButtonMode.ghost}
                                    onClick={onClickRight}
                                    title={rightTitle}
                                    icon={rightIcon}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="h-full w-full overflow-y-auto p-4 min-h-[180px]">
                        {children}
                    </div>
                </Dialog.Content>
            </Dialog.Portal>
        </Dialog.Root>
    );
};

export default DetachedBox;
