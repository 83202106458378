import { useMediaQuery } from "react-responsive";
import useEditMode from "./useEditMode";

export const breakpoints = { xs: 480, md: 600, lg: 800, xl: 960 };

export default function useBreakpoints() {
    const editMode = useEditMode();

    const lessThanXL = useMediaQuery({
        query: `(max-width: ${breakpoints.xl}px)`,
    });

    const lessThanLg = useMediaQuery({
        query: `(max-width: ${breakpoints.lg}px)`,
    });

    // < md breakpoint
    const lessThanMD = useMediaQuery({
        query: `(max-width: ${breakpoints.md}px)`,
    });

    const isMobileView = lessThanMD || editMode.mobilePreviewMode;

    return {
        breakpoints,
        lessThanMD,
        lessThanLg,
        lessThanXL,
        isMobileView,
        isDesktopView: !isMobileView,
    };
}
