export enum Shade {
    subtle = "subtle",
    light = "light",
    medium = "medium",
    dark = "dark",
    muted = "muted",
}

export const theme_shades = {
    default: {
        subtle: "#FFFFFF",
        light: "#F2F4F7",
        medium: "#9D9FA3",
        dark: "#000000",
    },
    gray: {
        subtle: "#F5F6F7",
        light: "#DCDEE0",
        medium: "#6C737A",
        dark: "#23282E",
        muted: "#888F95",
    },
    red: {
        subtle: "#FCEBE8",
        light: "#F5B9B0",
        medium: "#A32607",
        dark: "#290602",
        muted: "#A73F25",
    },
    orange: {
        subtle: "#FFF8F5",
        light: "#F5E5D7",
        medium: "#DE5C1B",
        dark: "#3D1A02",
        muted: "#C77D38",
    },
    brown: {
        subtle: "#F4F1ED",
        light: "#E0D3C5",
        medium: "#7A4B2C",
        dark: "#29190B",
        muted: "#897B6C",
    },
    yellow: {
        subtle: "#FFFBF0",
        light: "#F5EACE",
        medium: "#E0AC1B",
        dark: "#3D2F02",
        muted: "#C9952C",
    },
    green: {
        subtle: "#F3FAF2",
        light: "#BFE0BC",
        medium: "#25B856",
        dark: "#0F2D0D",
        muted: "#4A8745",
    },
    light_blue: {
        subtle: "#F2F8FA",
        light: "#BADFF5",
        medium: "#31A6F5",
        dark: "#021E29",
        muted: "#41708B",
    },
    blue: {
        subtle: "#F0F3FA",
        light: "#E3F2FD",
        medium: "#1D57F5",
        dark: "#020F29",
        muted: "#41558B",
    },
    purple: {
        subtle: "#EDEBFA",
        light: "#C2BAF5",
        medium: "#5533FF",
        dark: "#070229",
        muted: "#544E7E",
    },
    pink: {
        subtle: "#FAEDFA",
        light: "#F5CEEB",
        medium: "#A33B87",
        dark: "#290229",
        muted: "#874575",
    },
};

export const core_palette = {
    // Primary: main title, body text & button background for light mode
    primary: {
        light: "#1B1E22",
        dark: "rgba(243, 246, 249, 0.96)",
    },
    // Secondary: Subtitle & deemphasized text for light mode
    secondary: {
        light: "rgba(39,47,54,.64)",
        dark: "rgba(243, 246, 249, 0.72)",
    },
    // Placeholder & disclaimer text
    tertiary: {
        light: "rgba(39, 47, 54, 0.36)",
        dark: "rgba(243, 246, 249, 0.32)",
    },
    // Subtle button background and border
    quaternary: {
        light: "rgba(39, 47, 54, 0.09)",
        dark: "rgba(243, 246, 249, 0.16)",
    },
    // Input field background
    quinary: {
        light: "rgba(39, 47, 54, 0.03)",
        dark: "rgba(243, 246, 249, 0.08)",
    },
    inverse: {
        light: "#FFFFFF",
        dark: "#000000",
    },
    success: {
        light: "#0099FF",
        dark: "#005899",
    },
    critical: {
        light: "#FF3300",
        dark: "#FF3300",
    },
};
