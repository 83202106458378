export function useHostname() {
    const origin =
        typeof window !== "undefined" && window.location.origin
            ? window.location.origin
            : "";

    const isSnackSite = origin.includes("snack.site");
    const isLocalHost = process.env.NODE_ENV === "development";

    let hostManual = "my.store";
    if (isLocalHost) {
        hostManual = "localhost:3000";
    } else if (isSnackSite) {
        hostManual = "snack.site";
    }
    const hostname =
        typeof window !== "undefined" && window.location.hostname
            ? window.location.hostname
            : hostManual;

    const hostnameWithProtocol =
        typeof window !== "undefined" && window.location.origin
            ? `${origin}/`
            : `https://${hostname}/`;

    return {
        hostname,
        hostnameWithProtocol,
    };
}
