import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import {
    selectEditMode,
    selectMobilePreviewMode,
    setEditMode,
} from "store/adminMenuSlice";
import { useDevice } from "./useDevice";

export default function useEditMode() {
    const editMode = useSelector(selectEditMode);
    const { isMobileDevice } = useDevice();
    const mobilePreviewMode = useSelector(selectMobilePreviewMode);

    const router = useRouter();
    const isAdmin = router.pathname.slice(0, 6) === "/admin";
    const dispatch = useDispatch();

    const toggleEditMode = () => {
        dispatch(setEditMode(!editMode));
    };

    return {
        editMode: editMode && isAdmin && !isMobileDevice,
        toggleEditMode,
        mobilePreviewMode,
        isAdmin,
    };
}
