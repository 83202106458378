type MakeRequestParams = {
    url: string;
    method: "GET";
    body?: any;
};

const makeRequest = <T = unknown>({
    url,
    method,
    body,
}: MakeRequestParams): Promise<T> =>
    fetch(url, {
        method,
        headers: { "content-type": "application/json" },
        body: JSON.stringify(body),
    })
        .then(response => {
            if (response.ok) {
                return response.json();
            }
            return Promise.reject(response);
        })
        .then(result => result)
        .catch(err => {
            if (err instanceof Error) return err;
            return new Error(err);
        });

/**
 * Here `url` is just the resource name, not the full path.
 */
const makeRequestToApi = <T = unknown>({
    url,
    ...params
}: MakeRequestParams): Promise<T> =>
    makeRequest({ url: `/api/v0.1/${url}`, ...params });

const apiClient = {
    /**
     * Make a GET request to the API. The url parameter must not include the
     * API prefix, it should just be the resource name/path.
     *
     * e.g. get("profiles") would send a request to /api/v0.1/profiles/
     */
    get: <T = unknown>(url: string) =>
        makeRequestToApi<T>({ url, method: "GET" }),
};

export default apiClient;
