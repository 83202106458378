import Visitor from "components/profile/Visitor";
import { fetchAllProfiles, fetchSnackpassStoreAndProfile } from "db/helpers";
import { snackpass } from "db/snackpass";

export async function getStaticProps(context: {
    params: {
        username: string;
    };
}) {
    const { username } = context.params;
    const { profile, snackpassStore, errorMessage } =
        await fetchSnackpassStoreAndProfile(username);

    return {
        props: {
            profile,
            snackpassStore,
            errorMessage,
        },
        revalidate: 10, // Optionally add revalidation time in seconds
    };
}

export async function getStaticPaths() {
    // Fetch or define the list of usernames for which you want to generate pages
    const profiles = await fetchAllProfiles();

    const profileIds = Object.keys(profiles);

    // for each profile id, fetch getStoreById
    // and get the username from the store
    const usernames = await Promise.all(
        profileIds.map(async profileId => {
            const snackpassStore = await snackpass.getStoreById(profileId);
            return snackpassStore.slug;
        }),
    );

    const paths = usernames
        .filter(u => Boolean(u))
        .map(username => {
            return { params: { username } };
        });

    // fallback blocking will server-render pages on-demand if the path doesn't exist
    return { paths, fallback: "blocking" };
}

export default Visitor;
