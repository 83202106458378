import Head from "next/head";

type Props = {
    errorMessage: string;
};

export default function ErrorPage({ errorMessage }: Props) {
    return (
        <div className="h-screen flex flex-col justify-center items-center">
            <Head>
                <title>snack.site</title>
                <meta property="og:title" content="snack.site" />
                <meta name="description" content="Powered By Snackpass" />
                <link rel="icon" href="/favicon.ico" />
            </Head>
            <p className="text-xl">{errorMessage}</p>
        </div>
    );
}
